import React from 'react';

const TimerBlock = ({timerStatus, remainDay, remainHour, remainMinutes, remainSecond}) => {

  return (
    <div className='bg-secondary rounded-default px-4 md:px-9 pb-14px pt-6'>
      <div className='flex justify-around items-center'>
        <div className='w-20 md:w-91px h-20 md:h-91px rounded-lg bg-gray-dark flex justify-center items-center'>
          <div>
            <h2 className='text-30 font-bold text-center'>{remainDay}</h2>
            <p className='text-12 text-center'>Days</p>
          </div>
        </div>
        <div className='w-20 md:w-91px h-20 md:h-91px rounded-lg bg-gray-dark flex justify-center items-center'>
          <div>
            <h2 className='text-30 font-bold text-center'>{remainHour}</h2>
            <p className='text-12 text-center'>Hours</p>
          </div>
        </div>
        <div className='w-20 md:w-91px h-20 md:h-91px rounded-lg bg-gray-dark flex justify-center items-center'>
          <div>
            <h2 className='text-30 font-bold text-center'>{remainMinutes}</h2>
            <p className='text-12 text-center'>Minutes</p>
          </div>
        </div>
        <div className='w-20 md:w-91px h-20 md:h-91px rounded-lg bg-gray-dark flex justify-center items-center'>
          <div>
            <h2 className='text-30 font-bold text-center'>{remainSecond}</h2>
            <p className='text-12 text-center'>Seconds</p>
          </div>
        </div>
      </div>
      <p className='text-15 text-gray-light mt-4 text-center'>
        {timerStatus === 0 && 'Betero presale starting soon'}
        {timerStatus === 1 && 'Until Betero presale ends'}
        {timerStatus === 2 && 'Until you can withdraw your Betero tokens'}
      </p>
    </div>
  )
}

export default TimerBlock;