import React from 'react';
import Button from './Button';

const TermsPopup = (props) => {
  const { setOpenPopup, setAgreed } = props;

  const handleClick = () => {
    setAgreed(true);
    setOpenPopup(false);
  }
  return (
    <div className='popup-container'>
      <div
        className='w-full max-w-screen-sm mx-auto rounded-lg p-6 overflow-auto bg-primary opacity-90 non-scrollbar-box'
        style={{ height: '90%', maxHeight: 675 }}
      >
        <h4 className='text-19 font-bold mb-3'>Betero RISKS DISCLAIMER</h4>
        <div style={{ paddingBottom: 42 }}>
          <p className='text-12 font-bold mb-3'>Please read and understand the following content carefully before purchasing any product.</p>
          <p className='text-12 font-bold mb-3'>You should always seek independent professional advice before using any content or purchasing any products from Betero. We will accept no responsibility or liability as a result of any consequence of using our content or purchasing our products.</p>
          <p className='text-12 font-bold mb-3'>The following (along with our Terms and Conditions, usage policy and other policies as applicable and which may be updated from time to time) apply to all users of our website, customers, clients, readers or otherwise in relation to using, reading or purchasing (directly or indirectly) any Betero content, Betero products and the Betero Litepaper (hereinafter referred to as the “Litepaper”).</p>
          <p className='text-12 font-bold mb-6'>The following also applies to the Betero anyone who participates in the Betero presale or any other token or coin offering. This Litepaper is provided by the Betero team (hereinafter referred to as the “Betero Team”) and does not guarantee that any content in this Litepaper will remain the same in the future.</p>
          <ol className='text-12'>
            <li>1. The Litepaper is written to provide information on the Betero project. The Litepaper is descriptive and is in no way legally binding. Any content in the Litepaper should not be considered as an offer, invitation or an incentive to attract participation in any purchase of the product (or any future products).</li>
            <li>2. No warranties, promises, and/or representations of any kind, expressed or implied, are given as to the nature, standard, accuracy, or otherwise of the information provided in this Litepaper nor to the suitability or otherwise of the information to your circumstances.</li>
            <li>3. The Betero team does not provide financial advice, and this Litepaper does not constitute any relations between any (direct or indirect) buyer and the seller. Statements contained herein are for illustrative purposes only and are not legal or financial advice. Statements and information are based on future forecasts are uncertain as they include known and unknown risks, and the actual outcome may differ from the one predicted based on the statements and information herein, or the one that this Litepaper implies and presents.</li>
            <li>4. Capital at risk. The value of products and the income you may generate from them can fall as well as rise and are not guaranteed. Purchaser may not get any return or money from products purchased. The figures shown relate to potential or predicted performance. Past, present and future performance is not guaranteed and is not a reliable indicator of current or future results and should not be the sole factor of consideration when selecting a product or strategy.</li>
            <li>5. The Betero team shall not be liable for any loss or damage which may arise as a result of your use of (or inability to use) this Litepaper or from your use of (or failure to use) the information in this Litepaper regardless of prior notice being given or any predictable loss of: (1) profit, revenue, debt and all other forms of monetary damages; (2) Income, sales, capital loss, liability and other losses incurred during business transactions, business activities, and profit-related activities; (3) data loss or corruption; (4) incidental or special damages; (5) wasted or lost administrative time; (6) pure economic loss in whatever form. (1) – (6) apply in all and whatever nature, whether direct, indirect, consequential, punitive, or arising in contract, tort or otherwise.</li>
            <li>6. The contents of the Litepaper may change depending on the Betero development process, strategy, market changes, technological advances, and changes in initial coin offering (ICO) or token regulations in whichever jurisdiction(s) that apply. The Betero team is not obligated to inform or report to readers about events, changes (including regulatory or legal changes), platforms, plans, changes in estimates, or changes in tolerance in this Litepaper and it is users and purchasers responsibility to ensure they remain up to date with such changes. While we make every reasonable effort to ensure that all information: (i) in this Litepaper; and (ii) available on the Website is accurate and up to date, such material may not be complete, may change from time to time (with or without notice) and will never constitute professional advice (independent or otherwise).</li>
            <li>7. This Litepaper is aimed at providing information, for reference purposes, of the business being contemplated by the Betero team. It is not to offer any advice or recommendation according to products, content, strategy, law, tax, finance, accounting, regulations, or others. Note that acquisition and disposition of products (including tokens and coins) may incur tax implications, regulatory implications and foreign currency exchange implications for which Betero will bear no responsibility or liability at any time and will not and does not provide any advice on.</li>
            <li>8. The publication and distribution of this Litepaper is prohibited in countries where publication and distribution of Litepapers, tokens, coins and cryptocurrency are forbidden, blocked or illegal. The information contained in this Litepaper has not been validated or approved by any regulatory agency, regulator, oversight committee, government, institution or professional firm, and any action (direct or indirect) by users or purchsers that violates any applicable laws or regulations is not the responsibility of Betero or the Betero Team and Betero and the Betero team will accept no liability as a result.</li>
            <li>9. We have made every effort to prevent the distribution or publication of the Litepaper and Betero in countries where the legal and regulatory environment prohibits such content and/or products. It is the user and purchasers responsibility to understand and know the laws and regulations in their jurisdiction and adhere to them when using content and/or purchasing products in compliance with such laws and regulations. If you are prohibited from using the content or purchasing products, you must not access or use content from Betero or purchase products from Betero.</li>
            <li>10. The given English Litepaper and website are the main official source of information regarding the Betero project and the launch of Betero and the Betero token (“BTE”). The information contained in the Litepaper may be translated into other languages or used to communicate with potential partners and project participants and as a result the translated version may differ in interpretation or meaning when translated from English to that language. Betero accept no responsibility for differences or discrepancies in such interpretation, definitions or meaning.</li>
            <li>11. The Betero Litepaper, Betero and the Betero Team reserve all intellectual property rights to the extent not prohibited by law or regulation and any use of the Betero Litepaper, Betero and the Betero Team is strictly prohibited unless agreed in writing by Betero. You may download or print individual sections of the Litepaper only for personal use or other proprietary notices, but you are prohibited from using any material that may insinuate or infer partnership, joint venture, any form of relationship or no relationship with Betero. For the avoidance of doubt, no part of this Litepaper may be reproduced in whole or in part, reproduced electronically or otherwise, or modified, linked, or used for public or commercial purposes without the prior written consent of the Betero team.</li>
          </ol>
          <p className='text-12 font-bold mb-3 mt-4'>Betero (BTE) TOKENS</p>
          <p className='text-12 mb-6'>Betero does not recommend or advise purchasing tokens or products for speculative investment purposes. You should carefully consider and evaluate each of the risk factors and all information provided before deciding to participate in a product or a token sale.</p>
          <ol className='text-12'>
            <li>1. BTE is the native utility token of the Betero ecosystem.</li>
            <li>2. Users, clients and customers should not purchase BTE for investment purposes. Such tokens are not designed for investment purposes and shall not be considered as a type of investment. You acknowledge that all purchases of BTE are final and nonrefundable, and the Company is not required to provide a refund for any reason and that you will not receive money or other compensation or any form of a refund, and where you purchase BTE or a product you consent to no right of withdrawal from the fundraising.</li>
            <li>3. BTE Tokens do not entitle you to any equity, dividends, voting, governance rights or similar right or entitlement in the Betero company (including the corporate structure, holdings, subsidiaries, as a director, advisor, shareholder or beneficial owner) or in any of its affiliated companies.</li>
            <li>4. Citizens, nationals, resident (tax or otherwise) and/or green card holders of any jurisdiction which prohibits the possession, dissemination or communication of the Available Information and/or prohibits participation in the purchase of tokens or cryptocurrency or any such similar activity must not participate in or purchase products or BTE tokens.</li>
            <li>5. The purchasers personal information is required to distribute and control the sale of BTE tokens. Personal data is only stored for the purpose of verification and used solely to determine verification. Unless you opt in, your data will not be used to market or provide information on offers. Betero will not provide or sell your data to any third party.</li>
          </ol>
          <p className='text-12 font-bold mb-3 mt-4'>RISKS</p>
          <p className='text-12 font-bold mb-3'>Cryptocurrency and tokens come with inherent risks including the loss of purchase price, extreme price volatility and unpredictable market behaviour.</p>
          <ol className='text-12'>
            <li>1. Blockchain Risk: Blockchain system congestion may cause transactions to be processed late or be invalidated. The Betero team cannot be held accountable for any damage done by bugs, deficiencies or related problems to the underlying blockchain structure.</li>
            <li>2. Transaction privacy leakage: Your personal information is required to distribute and control BTE tokens in the purchasers' electronic wallets. BTE tokens stored in the wallets may be changed or lost due to an internal or external factor such as attack from malicious code, software bugs, blockchain networks error, at the request of a regulator (only where required to do so by law will we provide such information and in all cases we will only ever provide the minimum amount of information required by law) and more.</li>
            <li>3. Security vulnerabilities: The purchaser is responsible for taking appropriate measures in order to keep their wallet and BTE save from malicious attacks. The Betero team encourages every purchaser to inform themselves about potential security risks that come with using blockchain products. Betero and the Betero Team will not be responsible for any breaches of security or security vulnerabilities for using, transferring or otherwise in relation to Betero and/or BTE.</li>
            <li>4. E-Wallet compatibility risk: Participants must use an electronic wallet that is technically compatible with the BTE token to purchase or store BTE tokens or products. If a participant is using a different wallet, the participant may not be able to access the purchased BTE token and this may be lost. Betero and the Betero Team will not be responsible and will not reimburse for any loss or loss of BTE as a result of such incompatibility or loss derived from such incompatibility.</li>
            <li>5. Market volatility risk: Participants must be aware that cryptocurrency and tokens are extremely volatile and carry significant risk and financial risk. This can be impacted by events, rhetoric, understanding (or misunderstanding) for any or no reason and participants must ensure they are fully aware, accept and are able to deal with these risks, the volatility and the potential loss that may come as a direct or indirect result.</li>
          </ol>

        </div>

        <div className='flex justify-center'>
          <Button
            onClick={() => handleClick()}
            className='bg-green-primary rounded-full py-2 w-60'
          >
            Yes, I agree
          </Button>
        </div>
      </div>
      <div className={'overlay'} onClick={() => setOpenPopup(false)}/>
    </div>
  )
}

export default TermsPopup;